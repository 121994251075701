import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
// 重写了原型上的push方法，统一的处理了错误信息
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const getPage =
  (modules, page = 'index') =>
  () =>
    import(`@/views/${modules}/${page}`)
const getGlobalPage =
  (page = '') =>
  () =>
    import(`@/views/personal/global/${page}`)

Vue.use(VueRouter)

const routes = [
  {
    path: '/blog'
  },
  {
    path: '/blog/en'
  },
  //canting 特定链接
  {
    path: '/canting',
    name: 'home',
    component: getPage('home'),
    meta: {
      shouldH5: false,
      metaInfo: {
        title: '迎客移动 ENC Mobile | 美国手机卡 | 5G套餐无限流量 | 一卡双号',
        // name: 'viewport',
        // content: 'width=device-width,initial-scale=1.0',
        keywords: '',
        description:
          '迎客移动Enc Mobile提供高品质美国手机卡，套餐包含无限美国通话、免费中国电话、全球短信、5G高速流量、一卡双号，助您无缝连接中美两地。'
      }
    }
  },
  {
    path: '/',
    name: 'home',
    component: getPage('home'),
    meta: {
      shouldH5: false,
      metaInfo: {
        title: '迎客移动 ENC Mobile | 美国手机卡 | 5G套餐无限流量 | 一卡双号',
        // name: 'viewport',
        // content: 'width=device-width,initial-scale=1.0',
        keywords: '',
        description:
          '迎客移动Enc Mobile提供高品质美国手机卡，套餐包含无限美国通话、免费中国电话、全球短信、5G高速流量、一卡双号，助您无缝连接中美两地。'
      }
    }
  },
  {
    path: '/promotion',
    name: 'promotion',
    component: getPage('newYearActivities'),
    redirect: '/',
    meta: {
      noHeaderFooter: true,
      shouldH5: true,
      metaInfo: {
        title: '新春活动',
        name: 'viewport',
        content: 'width=device-width,initial-scale=1.0',
        keywords: '',
        description: ''
      }
    }
  },
  {
    path: '/login',
    name: 'login',
    component: getPage('login')
  },
  {
    path: '/activation',
    name: 'activation',
    component: getPage('activation'),
    redirect: '/activation/first',
    children: [
      {
        path: '/activation/first',
        name: 'activationFirst',
        component: getPage('activation/components/first'),
        meta: {
          shouldH5: true,
          metaInfo: {
            title: '激活手机卡',
            name: 'viewport',
            content: 'width=device-width,initial-scale=1.0'
          }
        }
      },
      {
        path: '/activation/second',
        name: 'activationSecond',
        component: getPage('activation/components/second'),
        meta: {
          shouldH5: true,
          metaInfo: {
            title: '激活手机卡',
            name: 'viewport',
            content: 'width=device-width,initial-scale=1.0'
          }
        }
      },
      {
        path: '/activation/secondOffline',
        name: 'activationSecondOffline',
        component: getPage('activation/components/secondOffline'),
        meta: {
          shouldH5: true,
          metaInfo: {
            title: '激活手机卡',
            name: 'viewport',
            content: 'width=device-width,initial-scale=1.0'
          }
        }
      },
      {
        path: '/activation/third',
        name: 'activationThird',
        component: getPage('activation/components/third'),
        meta: {
          shouldH5: true,
          metaInfo: {
            title: '激活手机卡',
            name: 'viewport',
            content: 'width=device-width,initial-scale=1.0'
          }
        }
      },
      // 全球漫游卡激活流程
      {
        path: '/activation/global/first',
        name: 'activationGlobalFirst',
        component: getPage('activation/global'),
        meta: {
          shouldH5: true,
          metaInfo: {
            title: '激活手机卡',
            name: 'viewport',
            content: 'width=device-width,initial-scale=1.0'
          }
        }
      },
      {
        path: '/activation/global/third',
        name: 'activationGlobalThird',
        component: getPage('activation/global/third'),
        meta: {
          shouldH5: true,
          metaInfo: {
            title: '激活手机卡',
            name: 'viewport',
            content: 'width=device-width,initial-scale=1.0'
          }
        }
      }
    ]
  },
  {
    path: '/personal',
    name: 'personal',
    component: getPage('personal', 'common'),
    meta: {
      hasPermission: true
    },
    children: [
      {
        path: '',
        component: getPage('personal'),
        meta: {
          hasCommon: true
        }
      },
      {
        path: '/person/conversionCard',
        component: getPage('personal', 'conversionCard'),
        meta: {
          hasCommon: true
        }
      },
      {
        path: '/person/scanInstall',
        component: getPage('personal', 'scanInstall'),
        meta: {
          hasCommon: true
        }
      },
      {
        path: '/personal/myinfo',
        name: 'myinfo',
        component: getPage('personal', 'myinfo'),
        meta: {
          hasCommon: true,
          hasPermission: true
        }
      },
      {
        path: '/resetpwd',
        name: 'resetpwd',
        component: getPage('personal', 'resetpwd'),
        meta: {
          hasCommon: true
        }
      },
      {
        path: '/package',
        name: 'package',
        component: getPage('personal', 'package'),
        meta: {
          hasCommon: true
        }
      },
      {
        path: '/mybill',
        name: 'mybill',
        component: getPage('personal', 'mybill'),
        meta: {
          hasCommon: true
        }
      },
      {
        path: '/mybank',
        name: 'mybank',
        component: getPage('personal', 'mybank'),
        meta: {
          hasCommon: true
        }
      },
      {
        path: '/callrecord',
        name: 'callrecord',
        component: getPage('personal/usageRecord', 'call-record'),
        meta: {
          hasCommon: true
        }
      },
      {
        path: '/internet',
        name: 'internet',
        component: getPage('personal/usageRecord', 'internet'),
        meta: {
          hasCommon: true
        }
      },
      {
        path: '/message',
        name: 'message',
        component: getPage('personal/usageRecord', 'message'),
        meta: {
          hasCommon: true
        }
      },
      {
        path: '/recharge',
        name: 'recharge',
        component: getPage('personal/usageRecord', 'recharge'),
        meta: {
          hasCommon: true
        }
      },
      // 全球数据漫游卡个人中心
      {
        path: '/global/personal/myinfo',
        name: 'myinfo',
        component: getGlobalPage('myinfo'),
        meta: {
          hasCommon: true,
          hasPermission: true
        }
      },
      {
        path: '/global/scanInstall',
        component: getGlobalPage('scanInstall'),
        meta: {
          hasCommon: true
        }
      },
      {
        path: '/global/renewalpackage',
        name: 'renewalpackage',
        component: getGlobalPage('renewalpackage'),
        meta: {
          hasCommon: true
        }
      },
      {
        path: '/global/renewalpackage/form',
        name: 'renewalpackageForm',
        component: getGlobalPage('renewalpackage/form.vue'),
        meta: {
          hasCommon: false,
          hasPermission: true
        }
      },
      {
        path: '/global/renewalpackage/result',
        name: 'renewalpackageResult',
        component: getGlobalPage('renewalpackage/result.vue'),
        meta: {
          hasCommon: false,
          hasPermission: true
        }
      },
      {
        path: '/global/myorder',
        name: 'myorder',
        component: getGlobalPage('myorder'),
        meta: {
          hasCommon: true
        }
      },
      // 充值-选择账号
      {
        path: '/personal/pay/changeAccount',
        name: 'changeAccount',
        component: getPage('personal/pay', 'changeAccount'),
        meta: {
          hasCommon: true,
          hasPermission: true // 个人中心路由需要前置拦截
        }
      },
      // 充值-确认支付
      {
        path: '/personal/pay/confirmPay',
        name: 'confirmPay',
        component: getPage('personal/pay', 'confirmPay'),
        meta: {
          hasCommon: false,
          hasPermission: true
        }
      },
      // 充值-支付结果
      {
        path: '/personal/pay/payStatus/:orderNo',
        name: 'payStatus',
        component: getPage('personal/pay', 'payStatus'),
        meta: {
          hasCommon: false,
          hasPermission: true
        }
      },
      // 充值-test
      {
        path: '/personal/pay/test',
        name: 'test',
        component: getPage('personal/pay', 'test'),
        meta: {
          hasCommon: false,
          hasPermission: true
        }
      },

      // 中美双号-流程页-未开通
      // {
      //   path: '/personal/doubleNumber/notAvailable',
      //   name: 'notAvailable',
      //   component: getPage('personal/doubleNumber', 'notAvailable'),
      //   meta: {
      //     hasCommon: true,
      //     hasPermission: true
      //   }
      // },
      // 中美双号-流程页-开通双号
      {
        path: '/personal/doubleNumber/openDouble',
        name: 'openDouble',
        component: getPage('personal/doubleNumber', 'openDouble'),
        meta: {
          hasCommon: true,
          hasPermission: true,
          hasIsSmsUser: true
        }
      },
      // 中美双号-流程页-开通状态
      {
        path: '/personal/doubleNumber/waitReview',
        name: 'waitReview',
        component: getPage('personal/doubleNumber', 'waitReview'),
        meta: {
          hasCommon: true,
          hasPermission: true,
          // hasOpen: true
          hasIsSmsUser: true
        }
      },
      // 自动续订
      {
        path: '/personal/autosubscription/subscription',
        name: 'subscription',
        component: getPage('personal/autosubscription', 'subscription'),
        meta: {
          hasCommon: true,
          hasPermission: true
        }
      },
      // 过度页
      {
        path: '/personal/transition-page',
        name: 'TransitionPage',
        component: getPage('personal', 'transitionPage'),
        meta: {
          hasCommon: false,
          hasPermission: false
        }
      },
      // 套餐续订
      {
        path: '/personal/businessManegement/bundleChange',
        name: 'personalBundle',
        component: getPage('personal/businessManegement/bundleChange', 'index'),
        meta: {
          hasCommon: true,
          hasPrice: false,
          hasPermission: true,
          hasIsSmsUser: true // 短信用户隔离
        }
      },
      {
        path: '/personal/businessManegement/bundleChange/form',
        name: 'personalBundleForm',
        component: getPage('personal/businessManegement/bundleChange', 'form'),
        meta: {
          hasCommon: false,
          hasPermission: true
        }
      },
      {
        path: '/personal/businessManegement/bundleChange/result/:orderNo',
        name: 'personalBundleResult/result',
        component: getPage(
          'personal/businessManegement/bundleChange',
          'result'
        ),
        meta: {
          hasCommon: false,
          hasPermission: true
        }
      },
      {
        path: '/personal/businessManegement/bundleChange/resultError',
        name: 'personalBundleResult/resultError',
        component: getPage(
          'personal/businessManegement/bundleChange',
          'resultError'
        ),
        meta: {
          hasCommon: false,
          hasPermission: true
        }
      },
      // 流量包
      {
        path: '/personal/businessManegement/flowPacket',
        name: 'flowPacket',
        component: getPage('personal/businessManegement/flowPacket', 'index'),
        meta: {
          hasCommon: true,
          hasPrice: true,
          hasPermission: true
        }
      },
      {
        path: '/personal/businessManegement/flowPacket/form',
        name: 'flowPacketForm',
        component: getPage('personal/businessManegement/flowPacket', 'form'),
        meta: {
          hasCommon: false,
          hasPermission: true
        }
      },
      {
        path: '/personal/businessManegement/flowPacket/result:/orderNo',
        name: 'flowPacketResult/result',
        component: getPage('personal/businessManegement/flowPacket', 'result'),
        meta: {
          hasCommon: false,
          hasPermission: true
        }
      },
      {
        path: '/personal/businessManegement/flowPacket/resultError',
        name: 'flowPacketResult/resultError',
        component: getPage(
          'personal/businessManegement/flowPacket',
          'resultError'
        ),
        meta: {
          hasCommon: false,
          hasPermission: true
        }
      },
      // 漫游包
      {
        path: '/personal/businessManegement/roamPack',
        name: 'roamPack',
        component: getPage('personal/businessManegement/roamPack', 'index'),
        meta: {
          hasCommon: true,
          hasPrice: true,
          hasPermission: true
        }
      },
      {
        path: '/personal/businessManegement/roamPack/form',
        name: 'roamPackForm',
        component: getPage('personal/businessManegement/roamPack', 'form'),
        meta: {
          hasCommon: false,
          hasPermission: true
        }
      },
      {
        path: '/personal/businessManegement/roamPack/result/:orderNo',
        name: 'roamPackResult/result',
        component: getPage('personal/businessManegement/roamPack', 'result'),
        meta: {
          hasCommon: false,
          hasPermission: true
        }
      },
      {
        path: '/personal/businessManegement/roamPack/resultError',
        name: 'roamPackResult/resultError',
        component: getPage(
          'personal/businessManegement/roamPack',
          'resultError'
        ),
        meta: {
          hasCommon: false,
          hasPermission: true
        }
      },
      {
        path: '/personal/upgradeNewCard',
        name: 'upgradeNewCard',
        component: getPage('personal', 'upgradeNewCard'),
        meta: {
          hasCommon: true,
          hasPermission: true
        }
      },
      {
        path: '/personal/wificall',
        name: 'wificall',
        component: getPage('personal', 'wificall'),
        meta: {
          hasCommon: true,
          hasPermission: true
        }
      },
      // 保号服务
      {
        path: '/personal/protect',
        name: 'protect',
        component: getPage('personal/protect', 'index'),
        meta: {
          hasCommon: true,
          hasPermission: true
        }
      },
      {
        path: '/personal/protect/payment',
        name: 'protect',
        component: getPage('personal/protect', 'payment'),
        meta: {
          hasCommon: false,
          hasPermission: true
        }
      },
      {
        path: '/personal/protect/result/:orderNo',
        name: 'protect',
        component: getPage('personal/protect', 'result'),
        meta: {
          hasCommon: false,
          hasPermission: true
        }
      }
    ]
  },
  // 中美双号-宣传页
  {
    path: '/personal/doubleNumber/publicity',
    name: 'publicity',
    component: getPage('personal/doubleNumber', 'publicity'),
    meta: {
      shouldH5: false,
      metaInfo: {
        title: ' ENC Mobile一卡双号｜美国手机号 + 中国虚拟号｜中美双号',
        // name: 'viewport',
        // content: 'width=device-width,initial-scale=1.0',
        keywords: '',
        description:
          '迎客移动ENC Mobile中美一卡双号服务，一张SIM卡拥有美国手机号和中国虚拟号，轻松无缝接收国内银行通知、电商短信和验证码。仅需$1.99/月，首月免费体验，无需额外SIM卡，沟通更省心。'
      }
    }
  },
  {
    path: '/plans',
    name: 'plans',
    component: getPage('plans'),
    redirect: '/plans/propagate',
    children: [
      {
        path: '/plans/propagate',
        name: 'propagate',
        component: getPage('plans/components/propagate'),
        meta: {
          shouldH5: false,
          metaInfo: {
            title: ' 迎客移动美国手机套餐｜畅享5G高速流量和无限通话服务',
            // name: 'viewport',
            // content: 'width=device-width,initial-scale=1.0',
            keywords: '',
            description:
              '迎客移动美国手机套餐，享受5G高速流量、无限美国通话和中国通话，无需担心国际资费，低至$10/月，流量套餐灵活可选，满足您的所有需求。'
          }
        }
      },
      {
        path: '/plans/global',
        name: 'global',
        component: getPage('plans/components/propagate', 'global'),
        meta: {
          shouldH5: false,
          metaInfo: {
            title: '全球数据SIM卡｜亚洲欧洲旅行优选｜ENC mobile迎客移动',
            // name: 'viewport',
            // content: 'width=device-width,initial-scale=1.0',
            keywords: '',
            description:
              'ENC Mobile迎客移动全球数据漫游卡，提供SIM和eSIM选择，支持5G LTE全球网络，适合前往欧洲、亚洲等多国旅行，享受快速、稳定的全球网络连接'
          }
        }
      },
      {
        path: '/plans/:plan(.*-plan)',
        name: 'bundleDetail',
        component: getPage('plans/components/bundleDetail'),
        meta: {
          shouldH5: false,
          metaInfo: {
            title: '',
            keywords: '',
            description: ''
          }
        }
      },
      {
        path: '/plans/bundleForm',
        name: 'bundleForm',
        component: getPage('plans/components/bundleForm')
      },
      {
        path: '/plans/bundleResult/:orderNo',
        name: 'bundleResult',
        component: getPage('plans/components/bundleResult')
      },
      {
        path: '/plans/bundleResultError',
        name: 'bundleResultError',
        component: getPage('plans/components/bundleResultError')
      }
    ]
  },
  // static-page
  {
    path: '/helpSupport', //帮助支持
    name: 'helpSupport',
    component: getPage('helpSupport'),
    redirect: '/helpSupport/commonProblem',
    children: [
      {
        path: '/helpSupport/commonProblem', // 常见问题
        name: 'commonProblem',
        component: getPage('helpSupport/components/commonProblem')
      },
      {
        path: '/coverage', // 信号覆盖
        name: 'signalCover',
        component: getPage('helpSupport/components/signalCover')
      },
      {
        path: '/tracking', // 物流查询
        name: 'logisticQuery',
        component: getPage('helpSupport/components/logisticQuery'),
        redirect: '/tracking/search',
        children: [
          {
            path: '/tracking/search',
            name: 'logisticQuerySearch',
            component: getPage(
              'helpSupport/components/logisticQuery/components/logisticSearch'
            )
          },
          {
            path: '/tracking/result',
            name: 'logisticQueryResult',
            component: getPage(
              'helpSupport/components/logisticQuery/components/logisticResult'
            )
          }
        ]
      },
      {
        path: '/rates', // 资费查询
        name: 'postageQuery',
        component: getPage('helpSupport/components/postageQuery')
      }
    ]
  },
  {
    path: '/aboutUs', //关于我们
    name: 'aboutUs',
    component: getPage('aboutUs'),
    redirect: '/aboutUs/companyIntro',
    children: [
      {
        path: '/aboutUs/companyIntro', // 公司介绍
        name: 'companyIntro',
        component: getPage('aboutUs/components/companyIntro')
      },
      {
        path: '/aboutUs/contactUs', // 联系我们
        name: 'contactUs',
        component: getPage('aboutUs/components/contactUs')
      }
    ]
  },
  {
    path: '/serviceTerms', //服务条款
    name: 'serviceTerms',
    component: getPage('serviceTerms'),
    redirect: '/serviceTerms/serviceClause',
    children: [
      {
        path: '/serviceTerms/serviceClause', //服务条款
        name: 'serviceClause',
        component: getPage('serviceTerms/components/serviceClause')
      }
    ]
  },
  // activity
  {
    path: '/campaign', //活动
    name: 'activity',
    component: getPage('activity', 'common'),
    redirect: '/activity/student',
    children: [
      {
        path: '/campaign/student', //学生活动首页
        name: 'studentIndex',
        component: getPage('activity/student'),
        meta: {
          noHeaderFooter: true,
          metaInfo: {
            title: '学生活动',
            name: 'viewport',
            content: 'width=device-width,initial-scale=1.0'
          }
        }
      },
      {
        path: '/campaign/student/form', //学生活动购卡form填写
        name: 'studentForm',
        component: getPage('activity/student/bundleForm'),
        meta: {
          shouldH5: true,
          metaInfo: {
            title: '学生活动',
            name: 'viewport',
            content: 'width=device-width,initial-scale=1.0'
          }
        }
      },
      {
        path: '/campaign/student/result', //学生活动购卡结果页面(成功)
        name: 'studentResult',
        component: getPage('activity/student/bundleResult'),
        meta: {
          shouldH5: true,
          metaInfo: {
            title: '学生活动',
            name: 'viewport',
            content: 'width=device-width,initial-scale=1.0'
          }
        }
      },
      {
        path: '/campaign/student/resultError', //学生活动购卡结果页面(失败)
        name: 'studentResultError',
        component: getPage('activity/student/bundleResultError'),
        meta: {
          shouldH5: true,
          metaInfo: {
            title: '学生活动',
            name: 'viewport',
            content: 'width=device-width,initial-scale=1.0'
          }
        }
      }
    ]
  },
  // 推荐好友
  {
    path: '/recommendFriends',
    name: 'recommendFriends',
    component: getPage('recommendFriends'),
    redirect: '/ReferAFriend',
    children: [
      {
        path: '/ReferAFriend', //服务条款
        name: 'recommendFriendsIndex',
        component: getPage('recommendFriends/components')
      }
    ]
  },

  //为了让以前的链接可以访问
  {
    path: '/recommendFriends/recommendFriendsIndex',
    name: 'recommendFriends',
    component: getPage('recommendFriends'),
    redirect: '/ReferAFriend',
    children: [
      {
        path: '/ReferAFriend', //服务条款
        name: 'recommendFriendsIndex',
        component: getPage('recommendFriends/components')
      }
    ]
  },
  // esim
  {
    path: '/esim',
    name: 'esim',
    component: getPage('esim'),
    redirect: '/esim/cardPurchase/index',
    children: [
      {
        path: '/esim/cardPurchase/index',
        name: 'index',
        component: getPage('esim/cardPurchase'),
        meta: {
          metaInfo: {
            title: '',
            name: '',
            content: ''
          }
        }
      }
    ]
  },
  {
    path: '/phone-detection', // 手机型号检测
    name: '/phone-detection',
    component: () => import('../views/phone/detection.vue'),
    meta: {
      title: '手机型号检测',
      auth: false // 是否需要登录
    }
  },
  // 微信签约
  {
    path: '/wechat-signing',
    name: 'wechatSigning',
    component: () => import('../views/personal/wechatSigning.vue'),
    meta: {
      title: '微信签约',
      auth: false // 是否需要登录
    }
  },
  // 成为代理商
  {
    path: '/agent',
    name: 'agent',
    component: () => import('@/views/aboutUs/agent/index.vue'),
    meta: {
      title: '成为代理商',
      auth: false // 是否需要登录
    }
  },
  // 线下门店查询
  {
    path: '/store',
    name: 'store',
    component: () => import('@/views/helpSupport/store/index.vue'),
    meta: {
      title: '线下门店',
      auth: false // 是否需要登录
    }
  },
  // 视频教程
  {
    path: '/videoSupport',
    name: 'video',
    component: () => import('@/views/helpSupport/video/index.vue'),
    meta: {
      title: '线下门店查询',
      auth: false // 是否需要登录
    }
  },
  {
    path: '/404',
    name: 'notFound',
    meta: {
      title: '404',
      auth: false // 不需要登录
    },
    component: () => import('../views/error/404.vue')
    // redirect: "/"
  },
  {
    path: '*', // 此处需特别注意置于最底部
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
