<template>
  <div id="app">
    <div v-if="!noHeaderFooter">
      <Headerh5 v-if="isMobile && shouldH5" />
      <Header v-else />
    </div>
    <div :class="!noHeaderFooter ? 'app-content' : ''">
      <router-view />
    </div>
    <div v-if="!noHeaderFooter">
      <Footer />
    </div>
    <RecommendDialog ref="recommendDialog" />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Headerh5 from '@/components/Headerh5.vue'
import Footer from '@/components/Footer.vue'
import RecommendDialog from '@/components/RecommendDialog.vue'
import getUrlAddressInfo from '@/utils/getUrlAddressInfo.js'
export default {
  components: {
    Header,
    Headerh5,
    Footer,
    RecommendDialog
  },
  data() {
    return {
      isMobile: false,
      shouldH5: false,
      platform: '',
      urlInfo: {}
    }
  },
  computed: {
    noHeaderFooter() {
      return this.$store.state.ifHeaderFooter.noHeaderFooter
    }
  },
  watch: {
    // 重构响应式时需要修改
    $route(to) {
      this.shouldH5 = to.meta.shouldH5
    }
  },
  mounted() {
    if (!sessionStorage.getItem('lang')) {
      this.$setLang()
    }
    this.isMobile = this._isMobile()
    this.urlInfo = getUrlAddressInfo()
    if (this.urlInfo && this.urlInfo.recommendCode) {
      this.$refs.recommendDialog.showModal = true
    }
    this.getReferralCode()
  },
  methods: {
    // 判断是否为移动端访问
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    },
    // sessionStorage 存储推荐码
    getReferralCode() {
      // 清空
      sessionStorage.setItem('promotion', '')
      sessionStorage.setItem('plans/recommendCode', '')
      let url = window.location.href
      try {
        let urlStr = url.split('?')[1]
        let obj = {}
        let paramsArr = urlStr.split('&')
        for (let i = 0, len = paramsArr.length; i < len; i++) {
          // 再通过 = 将每一个参数分割为 key:value 的形式
          let arr = paramsArr[i].split('=')
          obj[arr[0]] = arr[1]
        }
        this.platform = obj?.promotion || ''
        this.recommendCode = obj?.recommendCode || ''
        sessionStorage.setItem('promotion', this.platform)
        sessionStorage.setItem('plans/recommendCode', this.recommendCode)
      } catch {
        return
      }
    }
  }
}
</script>

<style lang="scss">
@media screen and (min-width: 768px) {
  #app {
    width: 100%;
    height: 100vh;
    min-width: 1280px;

    .app-content {
      padding-top: 112px;
    }
  }

  .el-input__inner::-webkit-input-placeholder {
    // 谷歌内核
    color: rgba(0, 0, 0, 0.25);
    font-size: 16px;
    font-weight: 400 !important;
  }

  .el-input__inner:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: rgba(0, 0, 0, 0.25);
    font-size: 16px;
    font-weight: 400 !important;
  }

  .el-textarea__inner::-webkit-input-placeholder,
  .el-input__inner::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: rgba(0, 0, 0, 0.25);
    font-size: 16px;
    font-weight: 400 !important;
  }

  .el-textarea__inner::-webkit-input-placeholder,
  .el-input__inner:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: rgba(0, 0, 0, 0.25);
    font-size: 16px;
    font-weight: 400 !important;
  }
  .el-popover {
    max-height: 500px;
    overflow: auto;
    &.esim-popover {
      padding: 24px;
      background: #f8f8fa;
    }
  }
}

@media screen and (max-width: 767px) {
  .el-input__inner::-webkit-input-placeholder {
    // 谷歌内核
    color: rgba(0, 0, 0, 0.25);
    font-size: 3.2vw;
    font-weight: 400 !important;
  }

  .el-input__inner:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: rgba(0, 0, 0, 0.25);
    font-size: 3.2vw;
    font-weight: 400 !important;
  }

  .el-textarea__inner::-webkit-input-placeholder,
  .el-input__inner::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: rgba(0, 0, 0, 0.25);
    font-size: 3.2vw;
    font-weight: 400 !important;
  }

  .el-textarea__inner::-webkit-input-placeholder,
  .el-input__inner:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: rgba(0, 0, 0, 0.25);
    font-size: 3.2vw;
    font-weight: 400 !important;
  }
}

@media screen and (max-width: 767px) {
  #app {
    min-width: 100%;
  }

  .el-message-box {
    width: 80% !important;
  }
}

button.el-button--primary.btn-yellow {
  border-color: #eeae16 !important;
  background-color: #eeae16 !important;

  &:hover,
  &:focus {
    background-color: #fcba1d !important;
    border-color: #fcba1d !important;
  }

  &:active {
    background-color: #eba83b !important;
    border-color: #eba83b !important;
  }
}
</style>
